<template>
  <v-container
    class="d-flex flex-column justify-center align-center"
    style="min-height: 100vh; margin-bottom: 150px; margin-top: 50px"
  >
    <!-- <p class="text-center px-4 py-4 mt-4" style="border: 2px dashed #f74747">
      <span style="font-weight: bold">Congratulations!</span> products should
      arrive shortly.
    </p> -->
    <v-row>
      <v-col cols="12" md="12" class="d-flex justify-center mx-auto">
        <div id="dataapp" ref="content">
          <div
            id="invoice"
            style="
              background-color: white;
              min-height: 100vh;
              font-size: 14px;
              line-height: 155%;
            "
            class="invoice-box mt-6"
          >
            <table cellpadding="0" cellspacing="0">
              <tr class="top">
                <td colspan="4">
                  <table style="border-bottom: 1px solid #333">
                    <tr>
                      <td class="logo">
                        <img
                          src="../../../../public/logo.png"
                          float="left"
                          
                        > 
                      </td>
                      <td class="from-class">
                        <div style="font-size: 25px; margin-bottom: 0px; font-weight: bold;">
                          INVOICE 
                        </div> 
                        <span > <strong>From:</strong>   {{ companyInfo.name }} </span><br />
                       <!-- From : {{ companyInfo.name }}<br /> -->
                        {{ companyInfo.phone }}<br />
                      </td>
                      <!-- <td class="from-class">
                        <span style="font-weight: bold ">From: </span><br />
                        {{ companyInfo.name }}<br />
                        {{ companyInfo.phone }}<br />
                      </td> -->
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                      <div class="parent-class" style="width:100%; display: flex;  column-gap: 300px; align-items: flex-start;">
                        <div class="subTo"  style="width: 50%;">                                                        
                            <span style="font-weight: bold">To: </span><br />
                            {{ billing_address_name }}<br />
                            {{ billing_address_phone_number }}<br />
                            {{ billing_address_address.slice(0,  billing_address_address.indexOf('|')) }}<br />
                            <!-- {{data.billing_address.location}}
                              {{data.billing_address.area}}<br/> --> 
                        </div>
                          <div class="subFrom" style="width: 50%;">
                            <span style="max-width: 120px">
                              <span>INVOICE: # </span
                              ><span>{{ order_info.id }}</span
                              ><br />
                              Date: {{ order_info.ordered_date }}<br />
                              <span
                                style="
                                  background-color: #ccc;
                                  min-width: 100%;
                                  padding: 2px; "
                                >Net Amount:
                                {{
                                  order_info.price_total_including_delivery
                                }}tk</span
                              ><br />
                              <span
                                style="font-size: 16px; text-align: center"
                                v-if="order_info.payment_method == 'COD'"
                                >CASH ON DELIVERY</span
                              >
                              <span
                                style="font-size: 20px; text-align: center"
                                v-if="order_info.payment_method != 'COD'"
                                >PAID</span
                              >
                            </span>                           
                          </div>
                      </div>
                </td>
              </tr>            
            </table>

            <table class="shrink-table">
              <tr class="heading">
                <td>Name</td>
                <td v-if="showColorColumn">Color</td>
                <td >Size</td>
                <!-- <td>Unit</td> -->
                <td>Unit Price</td>
                <td>Quantity</td>
                <td>Discount</td>
                <td>Amount</td>
              </tr>
              <tr
                class="item"
                v-for="(product, index) in order_info.orders"
                :key="index"
              >
                <!-- v-for="(product, index) in order_info.orders" -->
                <td>
                  {{ product.product_name }}
                </td>
                <td v-if="showColorColumn">{{ product.product_color }}</td>
                <td >{{ product.product_size }}</td>
                <!-- <td>{{ product.product_weight }}{{ product.product_unit }}</td> -->
                <td>{{ product.original_unit_price }}</td>
                <td>{{ product.total_quantity }}</td>
                <td>{{ product.product_discount }}</td>
                <td>{{ product.original_total_price }}</td>
              </tr>
            </table>

            <table class="shrink-table" id="app">
              <tr>
                <td style="min-width: 300px"></td>
                <td></td>
                <td
                  style="
                    background-color: #ddd;
                    font-size: 12px;
                    text-align: left;
                  "
                >
                  Sub Total:
                </td>
                <td style="background-color: #ddd; font-size: 12px">
                  {{ order_info.sub_price }}
                </td>
              </tr>
              <tr v-if="order_info.delivery_charge
              > 0" >
                <td></td>
                <td></td>
                <td
                  style="
                    background-color: #ddd;
                    font-size: 12px;
                    text-align: left;
                  "
                >
                  Delivery Charge:
                </td>
                <td style="background-color: #ddd; font-size: 12px">
                  + {{ order_info.delivery_charge
                  }}
                </td>
              </tr>
              <tr v-if="order_info.coupon_discount > 0">
                <td></td>
                <td></td>
                <td
                  style="
                    background-color: #ddd;
                    font-size: 12px;
                    text-align: left;
                  "
                >
                  Coupon Discount:
                </td>
                <td style="background-color: #ddd; font-size: 12px">
                  - {{ order_info.coupon_discount }}
                </td>
              </tr>
              <tr v-if="order_info.discount > 0  ">
                <td></td>
                <td></td>
                <td
                  style="background-color: #ddd;        font-size: 12px;
                    text-align: left;"
                >
                  Overall Discount:
                </td>
                <td style="background-color: #ddd; font-size: 12px" >
                  - {{ order_info.discount }}
                </td>
              </tr>

             
              <tr v-if="order_info.advanced_amount > 0">
                <td></td>
                <td></td>
                <td
                  style="background-color: #ddd;
                    font-size: 12px;
                    text-align: left;"
                >
                  Advanced Payment:
                </td>
                <td style="background-color: #ddd; font-size: 12px">
                  - {{order_info.advanced_amount}}
                  
                  <!-- - {{ order_info.overall_discount }} -->
                  
                </td>
              </tr>
              

             
           
              
              <tr v-if="vat > 0">
                <td></td>
                <td></td>
                <td
                  style="
                    background-color: #ddd;
                    font-size: 12px;
                    text-align: left;
                  "
                >
                  VAT:
                </td>
                <td style="background-color: #ddd; font-size: 12px">+ 0.00</td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td style="background-color: #ddd; text-align: left">
                  Grand Total:
                </td>
                <td style="background-color: #ddd">
                  <!-- {{ order_info.price_total_including_delivery }}/- -->
                  {{(Number(order_info.sub_price ) + Number(order_info.delivery_charge)- Number( order_info.discount ) - Number(order_info.advanced_amount))}}/-
                </td>
              </tr>
            </table>

            <div class="d-flex flex-row flex-row-reverse justify-space-between">
              <div style="min-width: 200px">
                <!-- <div
                  style="background-color: #ddd"
                  class="my-1 px-3 d-flex flex-row justify-space-between"
                >
                  <div>Subtotal:</div>
                  <div>{{ order_info.sub_price }}</div>
                </div> -->
                <!-- <div
                  style="background-color: #ddd"
                  class="my-1 px-3 d-flex flex-row justify-space-between"
                >
                  <div>Delivery Charge:</div>
                  <div>{{ order_info.estimated_delivery_charge }}</div>
                </div> -->

                <!-- <div
                  style="background-color: #ddd"
                  class="my-1 px-3 d-flex flex-row justify-space-between"
                >
                  <div>Coupon Discount</div>
                  <div>{{ order_info.coupon_discount }}</div>
                </div> -->

                <!-- <div
                  style="background-color: #ddd"
                  class="my-1 px-3 d-flex flex-row justify-space-between"
                >
                  <div>Overall Discount</div>
                  <div>{{ order_info.overall_discount }}</div>
                </div> -->
                <!-- <div
                  style="background-color: #ddd"
                  class="my-1 px-3 d-flex flex-row justify-space-between"
                >
                  <div>VAT</div>
                  <div></div>
                </div> -->
                <!-- <div
                  style="background-color: #ddd"
                  class="my-1 px-3 d-flex flex-row justify-space-between"
                >
                  <div style="font-size: 18px">Grand Total:</div>
                  <div style="font-size: 18px">
                    {{ order_info.price_total_including_delivery }}
                  </div>
                </div> -->
              </div>
              <div>
                <div v-if="paidStatus == 'COD'">
                  <span></span>
                </div>
                <div v-else-if="paidStatus != 'COD'">
                  <!-- <img class="paid-stamp" src="../assets/paid_round.png" /> -->
                </div>

                <!-- <div class="cards" style="transform: scale(0.8)">
                  <h5 class="card-title" v-text="barcodeTitle"></h5>
                  <svg
                    class="barcode"
                    :jsbarcode-format="barcodeFormat"
                    :jsbarcode-value="barcodeValue"
                    jsbarcode-textmargin="0"
                    jsbarcode-fontoptions="bold"
                  ></svg>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="text-center mx-3 my-3">
            <v-btn @click="printInvoice()" small color="#495464">PRINT</v-btn>
          </div> -->
        </div>
      </v-col>
    </v-row>

    <v-btn @click="printData()">PRINT</v-btn>
  </v-container>
</template>

<script defer>
// import { htmlToPaper } from 'vue-html-to-paper'

export default {
 
  data: () => ({
    invoiceinfo: {},
    orders: [],
    orderinfo: {},
    bill: [],
    specifications: [],
    companyinfo: {},
    snackbar: "",
    vat: 0,
    invoicearray: [],
    userdata: {},
    barcodeValue: 0,
    barcodeFormat: "auto",
    barcodeTitle: "Order Code",
    skus: [
      { format: "auto", title: "Order Code", value: 104955250 },
      // {format: 'upc', title: 'Honda Generator', value: 786102004731}
    ],

    order_info: {},
    companyInfo: {},

    billing_address_name: "",
    billing_address_phone_number: "",
    billing_address_address: "",
    paidStatus: "COD",

    products: [],

    // LOCAL LOGIC VAIABLES
    
    colorStatus: "none",
    sizeStatus: "none",
    showColorColumn: false,
    showSizeColumn: false,
    advancedPaymentStatus: true,
  }),
  methods: {
    initialize(item) {
      axios
        .get(`Cart/create_invoice/${this.$route.query.id}/`)
        .then((response) => {
          // console.log("this is all data", response.data)
          this.invoiceinfo = response.data.invoice_data;
          this.order_info = response.data.order_data;
          this.orders = response.data.order_data.orders;
          this.companyinfo = response.data.company_data;
          this.companyInfo = response.data.company_data;
          this.userdata = response.data.billing_address_data;
          this.billing_address_name = this.userdata.name;
          this.billing_address_phone_number = this.userdata.phone_number;
          this.billing_address_address = this.userdata.address;
          // console.log("table info", this.orders);
          // console.log("this is company data", this.userdata);
        });
    },

    //   printData() {
    // //   var divToPrint = document.getElementById("dataapp");
    // //   var style = "<style>";
    // //   style = style + "table {width: 100%;font: 17px Calibri;}";
    // //   style =
    // //     style +
    // //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    // //   style = style + "padding: 2px 3px;text-align: center;}";
    // //   style = style + "</style>";

    // //   var win = window.open("", "", "height=1900,width=1900");
    // //   win.document.write(style); //  add the style.
    // //   win.document.write(divToPrint.outerHTML);
    // //   win.document.close();
    // //   win.print();
    // window.print();
    // },
    printData() {
      var printContents = document.getElementById("dataapp").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },

    // printData() {
    //   var printContents = document.getElementById("dataapp").innerHTML;
    //   var originalContents = document.body.innerHTML;

    //   document.body.innerHTML = printContents;
    //   window.print();

    //   document.body.innerHTML = originalContents;
    // },
    // printData() {
    //   //this.$htmlToPaper("dataapp");
    //   this.$htmlToPaper("dataapp");

    //   // this.htmlToPaper("dataapp");
    // },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style>
@media only screen and (max-width: 1060px) {
  .congratulations-container {
    width: 500px;
  }
  .order-table {
    max-width: 350px;
  }
}
@media only screen and (max-width: 960px) {
  /* .congratulations-column {
    display: flex;
    justify-content: center;
  }

   .congratulations-container {
    width: 500px;
  } */

  .congratulations-column {
    text-align: center !important;
  }

  .order-table {
    max-width: 350px;
  }
}
@media only screen and (max-width: 600px) {
  .congratulations-container {
    width: 350px;
  }

  .order-table {
    max-width: 350px;
  }
}

/*
.congratulations-gif{
  width: 35%;
} */

/* INVOICE STYLE*/
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 50px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}


.logo img {
  width: 120px;
  height: 90px;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.data-table table tr.top table td {
  background-color: #f2f2f2;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  /* border-bottom: 1px solid #333; */
  padding-bottom: 20px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
 
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

.shrink-table {
}
@media screen and (max-width: 480px) {
  .shrink-table {
    transform: scale(0.9);
    margin-left: -20px;
  }
}
@media screen and (max-width: 426px) {
  .shrink-table {
    transform: scale(0.8);
    margin-left: -30px;
  }
}
@media screen and (max-width: 390px) {
  .shrink-table {
    transform: scale(0.75);
    margin-left: -60px;
  }
}
.paid-stamp {
  margin-top: -10px;
  max-width: 190px;
}

.long-address-section {
  max-width: 200px;
}

@media only screen and (max-width: 600px) {
  .paid-stamp {
    max-width: 140px;
  }
  .invoice-box {
    padding: 10px;
  }
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }
  .long-address-section {
    text-align: center;
    margin: auto;
  }
  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}
.rtl table {
  text-align: right;
}
.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>



